import "./App.css";

import { useState, useEffect } from "react";

const say = ({ voice, text, onEnd }) => {
  const pitch = 1;
  const rate = 1;

  var utterance = new SpeechSynthesisUtterance(text);
  utterance.voice = voice;
  utterance.pitch = pitch;
  utterance.rate = rate;
  if (onEnd) {
    utterance.addEventListener("end", onEnd);
  }
  speechSynthesis.speak(utterance);
};

const parts = [
  {
    title: "Lay down on your back and interlink your hands behind your head...",
    gap: 6000,
  },
  { title: "Focus your attention to your hands...", gap: 8000 },
  { title: "Move your eyes in the direction of 3 o'clock...", gap: 3000 },
  {
    title: "Hold this position until you yawn swallow or sigh...",
    gap: 3000,
  },
  { title: "This can take between 10 seconds and 3 minutes...", gap: 10000 },
  { title: "Take as much time as you need...", gap: 10000 },
  { title: "Thank you", gap: 3000 },
];

const App = () => {
  const [currentPart, setCurrentPart] = useState(-1);
  const [voices, setVoices] = useState([]);
  const [voice, setVoice] = useState(null);

  useEffect(() => {
    const populateVoiceList = () => {
      const sortedVoices = speechSynthesis.getVoices().sort(function (a, b) {
        const aName = a.name.toUpperCase(),
          bName = b.name.toUpperCase();
        if (aName < bName) return -1;
        else if (aName === bName) return 0;
        else return +1;
      });
      setVoices(sortedVoices);
    };

    populateVoiceList();

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = populateVoiceList;
    }
  }, []);

  useEffect(() => {
    if (!voice) {
      setVoice(voices.find((v) => v.lang === "en-US"));
    }
  }, [voices, voice]);

  useEffect(() => {
    if (currentPart < 0) {
      return;
    }

    say({
      voice: voice,
      text: parts[currentPart].title,
      onEnd: () => {
        setTimeout(() => {
          if (currentPart === parts.length - 1) {
            setCurrentPart(-1);
          } else {
            setCurrentPart(currentPart + 1);
          }
        }, parts[currentPart].gap);
      },
    });
  }, [currentPart, voice]);

  useEffect(() => say({ voice: voice, text: "This is my new voice" }), [voice]);

  const handleStartClick = () => {
    setCurrentPart(0);
  };

  const handleVoiceChange = (e) => {
    for (var i = 0; i < voices.length; i++) {
      if (voices[i].name === e.target.value) {
        setVoice(voices[i]);
        return;
      }
    }
  };

  return (
    <div className="p-10">
      <h1 className="text-4xl text-gray-700 font-bold mb-8">
        Vagus Nerve Reset
      </h1>
      {
        <>
          {currentPart === -1 && (
            <>
              <div className="text-xl text-gray-700 mb-4">
                If you're anxious or stressed you can use this quick technique to reset
                your Vagus Nerve.
              </div>
              <div className="border p-3">
                {voices.length > 0 && voice && (
                  <>
                    <div className="mb-2">
                      <label
                        htmlFor="choose_voice"
                        className="text-lg font-bold text-gray-700"
                      >
                        Voice
                      </label>
                    </div>
                    <div className="mb-3">
                      <select
                        defaultValue={voice.name}
                        id="choose_voice"
                        className="rounded border p-3 bg-white text-2xl text-gray-700"
                        onChange={handleVoiceChange}
                      >
                        {voices.map((voice) => (
                          <option key={voice.name} value={voice.name}>
                            {voice.name} - {voice.lang}{" "}
                            {voice.default && "DEFAULT"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                <div>
                  <button
                    onClick={handleStartClick}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Start
                  </button>
                </div>
              </div>
            </>
          )}

          {currentPart !== -1 && (
            <div className="text-3xl text-gray-600 font-bold border p-3">
              {parts[currentPart].title}
            </div>
          )}
        </>
      }
    </div>
  );
};

export default App;
